import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden, Button,Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;
  const location = useLocation();
  const classes = useStyles((theme) => ({
    root: {
    
      // boxShadow: "none",
    //   "& .MuiAppBar-colorPrimary": {
    //     backgroundColor: "transparent",
    // },
    },

    navBarButton: {
      color: theme.palette.text.primary,
    },

    LogoStyle:{
      display:'flex',
      // width: '100%',
      justifyContent:'center',
      fontWeight:'700',
      color: theme.palette.primary.dark,
    }
  }));

  return (
    <AppBar style={{backgroundColor:"white"}}
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink
          to="/"
          style={{ margin: "0px", padding: "0", height: "100%", width:"100%" }}
          // style={{ margin: "0px", padding: "0", height: "70px" }}
        >
          {/* <img
            alt="Logo"
            src="/images/greyHelixLogo.jpg"
            height="100%"
            width="auto"
            style={{ margin: "0px", padding: "0" }}
          /> */}
          <div className={classes.LogoStyle} style={{ display: "flex", justifyContent: "center"}}>
        <img
            alt="Logo"
            src="/images/greyHelix.svg"
            // src="/images/greyHelixLogo.jpg"
            height="100%"      
            width="auto"
            style={{margin:"0px", padding:"0", marginRight:"10px"}}
          />
          <Typography
                  className={classes.title}
                  color="textSecondary"
                  variant="h3">
                  GREYHELIX
                </Typography>
        
        </div>
        </RouterLink>
        <div className={classes.flexGrow} />
        {location.pathname === "/home" ? (
          <div>
            <RouterLink to="/about">
              <Button className={classes.navBarButton}  >About</Button>
            </RouterLink>
            <RouterLink to="/sign-in">
              <Button className={classes.navBarButton} >Sign in</Button>
            </RouterLink>
          </div>
        ) : (
          ""
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
