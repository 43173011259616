import { colors } from '@material-ui/core';
import globalcolors from './global.scss';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    // contrastText: white,
    // secondary:"#808080",
    // dark: "#0B265B",
    // main: "#236CEF",
    // light: "#02B9FC",
    contrastText:globalcolors.white ,
    secondary:globalcolors.Gray,
    dark: globalcolors.darkblue,
    main: globalcolors.mainBlue,
    light: globalcolors.lightBlue,
    transparent:colors.transparent,
    Btn:"#E8ECF5"
  },
  secondary: {
    // contrastText: white,
    // dark: colors.blue[900],
    // main: colors.blue['A400'],
    // light: colors.blue['A400'],

    contrastText: globalcolors.white,
    dark: colors.blue[900],
    main: colors.blue['A400'],
    light: colors.blue['A400'],
    // Gray:"#808080"
    Gray:globalcolors.Gray
  },
  success: {
    contrastText: globalcolors.white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    // contrastText: white,
    contrastText:globalcolors.white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText:globalcolors.white,
    // contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText:globalcolors.white,
    // contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    // primary: colors.blueGrey[900],
    // primary:"#0B265B",
    primary: globalcolors.darkblue,
    // secondary: colors.blueGrey[600],
    // secondary: "#0B265B",
    secondary: globalcolors.darkblue,
    // Gray:"#808080",
    Gray:globalcolors.Gray,
    link: colors.blue[600]
  },
  Casestext: {
    // primary: colors.grey[900],
    primary:"Gray",
    // secondary: colors.blueGrey[600],
    secondary: "#9395A3",
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
