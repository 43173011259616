import React, { useContext, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import Minimal from "./layout/Minimal/Minimal";
import Main from "./layout/Main/Main";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import { AuthContext } from "./common/context/auth-context";
import Refresh from "./pages/Refresh";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useHttpClient } from "./common/hooks/http-hook";
const Dashboard = React.lazy(() => import("./pages/Dashboard")); //lazy load code splitting
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword")); //lazy load code splitting
const ResetPassword = React.lazy(() => import("./pages/ResetPassword")); //lazy load code splitting
const Account = React.lazy(() => import("./pages/Account"));
const Cases = React.lazy(() => import("./pages/Cases"));
// const Invoice=React.lazy(() => import("./pages/Invoices"));
const Appointment = React.lazy(() => import("./pages/Appointment"));
//lazy load code splitting
const Home = React.lazy(() => import("./pages/Home"));
const About = React.lazy(() => import("./pages/About"));

const Routes = () => {
  const auth = useContext(AuthContext);
  let routes;
  let search = window.location.search; //confirm email
  let params = new URLSearchParams(search);
  let key = params.get("key");
  let dashboard = "/dashboard";
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const tokenLogin = async () => {
      const token = Cookies.get("ghAuth");
      if(token){
        try {
          const userId = jwt_decode(token).userId;
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "/api/users/user/" + userId,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );if (responseData.token) {
            auth.login(
              responseData.userId,
              responseData.profileId,
              responseData.userName,
              responseData.email,
              responseData.tenant,
              responseData.token,
              true,
              responseData.status,
              true,
              responseData.stripeId
            );
          }
        } catch (err) {}
      }
    }
    tokenLogin();
  },[]);

  if (key) {
    dashboard = "/dashboard?key=" + key; //send confirmation key to dashboard if logged in.
  }
  // {console.log("Current ",currentUrl)}
  if (
    !auth.hasCase &&
    auth.stripeStatus &&
    auth.stripeStatus.status === "canceled" &&
    auth.isLoggedIn
  )
    routes = (
      <Switch>
        <Route
          path="/account"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Account {...props} />
            </Main>
          )}
        />
        <Redirect to="/account?billing=true" />
      </Switch>
    );
  else if (auth.isLoggedIn)
    //logged in routes
    routes = (
      <Switch>
        <Route
          path="/dashboard"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Dashboard {...props} />
            </Main>
          )}
        />
        
        <Route
          path="/refresh"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Refresh {...props} />
            </Main>
          )}
        />
        <Route
          path="/account"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Account {...props} />
            </Main>
          )}
        />
        <Route
          path="/cases"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Cases {...props} />
            </Main>
          )}
        />
        <Route
          path="/appointment"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Appointment {...props} />
            </Main>
          )}
        />

        {/* <Route
          path="/invoices"
          exact
          render={(props) => (
            <Main tenantname={auth.tenant}>
              <Invoice {...props} />
            </Main>
          )}
        /> */}
        <Redirect to={dashboard} />
      </Switch>
    );
  else if(!auth.isLoggedIn)
    routes = (
      <Switch>
        <Route
          path="/sign-in"
          exact
          render={(props) => (
            <Minimal>
              <SignIn {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/sign-up"
          exact
          render={(props) => (
            <Minimal>
              <SignUp {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/ForgotPassword"
          exact
          render={(props) => (
            <Minimal>
              <ForgotPassword {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/resetpw"
          exact
          render={(props) => (
            <Minimal>
              <ResetPassword {...props} />
            </Minimal>
          )}
        />
        {/* <Route
          path="/home"
          exact
          render={(props) => (
            <Minimal>
              <Home {...props} />
            </Minimal>
          )}
        />
        <Route
          path="/about"
          exact
          render={(props) => (
            <Minimal>
              <About {...props} />
            </Minimal>
          )}
        /> */}
        {!auth.isLoggedIn &&
          <Redirect to="/sign-in" />
        }
      </Switch>
    );

  return routes;
};

export default Routes;
