import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { useHttpClient } from "../common/hooks/http-hook";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  FormHelperText,
  Checkbox,
  Box,
  Card,
  CardContent,
  GridListTile,
} from "@material-ui/core";
import ErrorModal from "../common/UI/ErrorModal";
import LoadingSpinner from "../common/UI/LoadingSpinner";
import {
  Facebook as FacebookIcon,
  Google as GoogleIcon,
} from "../common/UI/icons";
import { AuthContext } from "../common/context/auth-context";
import { useConfirmEmail } from "../common/hooks/confirmemail-hook";
import { useForm } from "../common/hooks/form-hook";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  rememberMe: {
    presence: { allowEmpty: true, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingLeft: 100,
    paddingRight: 75,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    boxShadow:'0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    backgroundColor:'white',
    borderRadius:'10px'
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  rememberMe: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  alreadyLoginBox: {
    marginTop: theme.spacing(1),
    marginBottom:theme.spacing(1)
  },
  linkClass:{
    fontWeight:"bold",
    color:theme.palette.blue,
    cursor: "pointer",
    textDecoration:"none"
  }
}));

const SignIn = (props) => {
  const auth = useContext(AuthContext);
  if (auth.isLoggedIn) auth.logout();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);
  const { confirmEmail, clearConfirmEmail } = useConfirmEmail();

  const redirectSignInHandler=()=>{
    auth.logout(false)
  }
  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/users/login",
        "POST",
        JSON.stringify({
          email: formState.values.email,
          password: formState.values.password,
          rememberMe:formState.values.rememberMe?"yes":"no",
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.token) {
        console.log(responseData)
        auth.login(
          responseData.userId,
          responseData.profileId,
          responseData.userName,
          responseData.email,
          responseData.tenant,
          responseData.token,
          true,
          responseData.status,
          formState.values.rememberMe,
          responseData.stripeId
        );
      }
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      {confirmEmail && (
        <ErrorModal
          open={!!confirmEmail}
          title="Email Confirmation"
          alert={confirmEmail}
          clearError={clearConfirmEmail}
        />
      )}
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Signing Up"
          alert={error}
          clearError={clearError}
        />
      )}
      {auth.error && (
        <ErrorModal
          open={!!auth.error}
          title="Error Signing Up"
          alert={auth.error}
          clearError={auth.clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.content}>
        <div className={classes.content}>
          <div className={classes.contentHeader}></div>
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={handleSignIn}>
              <Typography className={classes.title} variant="h2">
                Sign in
              </Typography>
                <>
                  <TextField
                    className={classes.textField}
                    error={hasError("email")}
                    fullWidth
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    label="Email address"
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email || ""}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError("password")}
                    fullWidth
                    helperText={
                      hasError("password")
                        ? formState.errors.password[0]
                        : null
                    }
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    // disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    Don't have an account?{" "}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      Sign up
                    </Link>
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    {" "}
                    Forgot your password?{" "}
                    <Link
                      component={RouterLink}
                      to="/ForgotPassword"
                      variant="h6"
                    >
                      Reset Password
                    </Link>
                  </Typography>
                </>
            </form>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
