import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme/";
import "./assets/scss/index.scss";
import LoadingSpinner from "./common/UI/LoadingSpinner";
import Routes from "./Routes";
import validate from "validate.js";
import validators from "./common/validators";
import { useAuth } from "./common/hooks/auth-hook";
import { AuthContext } from "./common/context/auth-context";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import RouteChangeTracker from "./utils/RouteChangeTracker";
import * as Sentry from "@sentry/react";
import AppContext, { ProviderValues } from "./common/context/app-context";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

validate.validators = {
  //add checkbox validator to validate.js
  ...validate.validators,
  ...validators,
};

const App = () => {
  const {
    token,
    hasCase,
    userId,
    profileId,
    userName,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    stripeId,
  } = useAuth();
  const [cases, setCases] = useState([]);
  const [appointment, setAppointment] = useState([]);

  function EditCase(props) {
  const updatedlist=cases.map((c) => {
    if(c._id==props._id){
      return (
        {
          _id:props._id,
          caseStatus:props.caseStatus,
          profileId:props.profileId,
          clientName:props.clientName,
          clientPhoneNumber:props.clientPhoneNumber,
          clientEmail:props.clientEmail,
          caseType:props.caseType
        }
      )
    }
    return c 
  });
    return updatedlist;
  }
  function EditAppointment(props) {

    const updatedlist=appointment.map((c) => {
      if(c._id==props._id){
        return (
          {
            _id:props.caseId._id,
            caseId:props.caseId,
            appointmentStatus:props.appointmentStatus,
            profileId:props.profileId,
            appointmentDate:props.appointmentDate,
            location:props.location,
          }
        )
      }
      return c 
      });
      return updatedlist;
  }
  const dispatchCaseEvent = (actionType, payload) => {
    switch (actionType) {
      case "SET_CASES":
        setCases([...payload.allCases]);
        return;
      case "ADD_CASE":
        setCases([...cases, payload.newCase]);
        return;
      case "EDIT_CASES":
        let updatedlist=EditCase(payload.editCase);
        setCases([...updatedlist]);
        return;
      case "REMOVE_ALL_CASE":
        setCases([]);
        return;
        case "SET_APPOINTMENTS":
          setAppointment([...payload.allAppointment]);
          return;
        case "ADD_APPOINTMENT":
          setAppointment([...appointment, payload.newAppointment]);
          return;
        case "EDIT_APPOINTMENT":
          let updatedAppointmentlist=EditAppointment(payload.editAppointment);
         
          setAppointment([...updatedAppointmentlist]);
          return;
        case "REMOVE_ALL_APPOINTMENTS":
          setAppointment([]);
          return;
      default:
        return;
    }
  };
  return (
    <Elements stripe={stripePromise}>
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          hasCase: hasCase,
          userId: userId,
          profileId: profileId,
          userName: userName,
          email: email,
          tenant: tenant,
          error: error,
          token: token,
          login: login,
          logout: logout,
          clearError: clearError,
          updateSettings: updateSettings,
          authProvider: authProvider,
          stripeStatus: stripeStatus,
          stripeId: stripeId,
        }}
      >
        <AppContext.Provider value={{ cases,appointment, dispatchCaseEvent }}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <RouteChangeTracker />
              <Suspense
                fallback={
                  <div align="center">
                    <LoadingSpinner open={true} />
                  </div>
                }
              >
                <Routes />
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </AppContext.Provider>
      </AuthContext.Provider>
    </Elements>
  );
};

export default Sentry.withProfiler(App);
