import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import dotenv from  'dotenv';
import { store } from './store/store';

const history = createBrowserHistory()
const hash = history.location.hash;
if (hash) {
  const path = hash.replace(/^#!/, '');
  if (path) {
    history.replace(path);
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  tracesSampleRate: process.env.REACT_APP_ENVIRONMENT == "production" ? 0.5 : 1.0,
});

ReactGA.initialize("G-D6Q0GJK6JD");
ReactGA.send("pageview");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
