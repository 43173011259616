import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer, Typography } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import InputIcon from "@material-ui/icons/Input";
import DescriptionIcon from "@material-ui/icons/Description";
import SidebarNav from "./SidebarNav";
import { CloseOutlined } from "@material-ui/icons";
import AlertModal from "../../../pages/components/alertModal/alertModal";
import { AuthContext } from "../../../common/context/auth-context";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "240px",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: 'flex',
      flexDirection:"row",
      justifyContent:"space-between",
      padding:"16px",
    },
  },
  crossbtn:{
    display:"none",
    [theme.breakpoints.down("sm")]: {
      display:"block",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flexF",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  tenant: {
    marginLeft: theme.spacing(1),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const [menuOpen, setmenuOpen] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const classes = useStyles();
  const [closeModal, setCloseModal] = useState(false);
  const auth = useContext(AuthContext);
  const Uperpages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    /* {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Products',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Typography',
      href: '/typography',
      icon: <TextFieldsIcon />
    },
    {
      title: 'Icons',
      href: '/icons',
      icon: <ImageIcon />
    },*/
    // {
    //   title: "Account",
    //   href: "/account",
    //   icon: <AccountBoxIcon />,
    // },
    {
      title: "Cases",
      href: "/cases",
      icon: <TextFieldsIcon />
    },
    {
      title: "Appointment",
      href: "/appointment",
      icon: <DescriptionIcon />
    },
    // {
    //   title: "Invoices",
    //   href: "/invoices",
    //   icon: <DescriptionIcon />
    // },

    // {
    //   title: "Logout",
    //   href: "/sign-in",
    //   logout: true,
    //   icon: <InputIcon />,
    // },
    /* {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }*/
  ];
  const Lowerpages = [
    {
      title: "Account",
      href: "/account",
      icon: <AccountBoxIcon />
    },
    {
      title: "Logout",
      href: "/sign-in",
      logout: true,
      icon: <InputIcon />
    }
  ];
const handleClose=()=>{
  onClose();
};
// const handleCloseMenu=(value)=>{
//   console.log(value);
//   // onClose();
// };
const handleSidebarClose = () => {
  setOpenSidebar(false);
};
const handleSidebarOpen = () => {
  setOpenSidebar(true);
};

const cancelLogout = () => {
  setCloseModal(false);
};
const handleLogout = () => {
  setCloseModal(false);
  auth.logout();
};
const LogoutModal=(value)=>{
console.log(value);
setCloseModal(true);
};
const myhandleEvent = (value) => {
  onClose();
  console.log("clicked")
  if(value=="Logout"){
    setCloseModal(true);
  };
}
return (
  <div>
  {/* {console.log(closeModal,"Im clicked")} */}
{closeModal && (
      <AlertModal
        open={closeModal}
        title={"Are You Sure You Want to Log Out?"}
        rightButton={"Yes, Log Out"}
        cancelLogout={cancelLogout}
        handleLogout={handleLogout}
      />
    )}
     <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography align='left' variant='h5' className={classes.tenant}>
        {/* {props.tenantname} */}
        Assurance DNA
      </Typography>
      {/* <Divider className={classes.divider} /> */}
      <SidebarNav className={classes.nav} Lowerpages={Lowerpages} Uperpages={Uperpages} onPress={(title)=>{myhandleEvent(title)}} />
      {/* <SidebarNav className={classes.nav} Lowerpages={Lowerpages} Uperpages={Uperpages} onClick={() =>LogoutModal(props.title)} /> */}
    </div>
    <div className={classes.crossbtn}>
      <CloseOutlined onClick={handleClose}/>
      </div>
    </Drawer>
</div>
   
  );
};

export default Sidebar;
