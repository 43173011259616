import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api';
import { authSlice } from './slices/authSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        authSlice
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware()
            .concat(api.middleware)
});

setupListeners(store.dispatch);

export default store;