import { useState, useEffect } from "react";
import validate from "validate.js";

export const useForm = (schema, initalValues) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: initalValues ? initalValues : {},
    touched: {},
    errors: {},
  });
  useEffect(() => {
    if (initalValues) {
      setFormState((formState) => ({
        ...formState,
        values: initalValues,
      }));
    }
  }, [initalValues]);
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, schema]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return { hasError, handleChange, formState };
};
