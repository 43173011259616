import {createContext} from 'react';

export const AuthContext = createContext({
    isLoggedIn: false,
    hasCase:true, 
    userId: null,   
    profileId:null,
    userName:null,
    email: null,
    tenant: null,
    error: null,
    authProvider: null, 
    stripeStatus: null,
    rememberMe:null,
    stripeId:null,
    login: ()=>{}, 
    logout: ()=>{},
    clearError: ()=>{},
    updateSettings: ()=>{},
});

