/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useContext, useState, UseEffect, useEffect } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import {
  List,
  ListItem,
  Button,
  colors,
  Typography,
  Link,
} from "@material-ui/core";
import { AuthContext } from "../../../common/context/auth-context";
import AlertModal from "../../../pages/components/alertModal/alertModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "90%",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.dark,
    },
  },
  classmenu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = ({onPress,...props}) => {
  // const [closeModal, setCloseModal] = React.useState(false);
  // const { pages, className, ...rest } = props;
  const { Uperpages, Lowerpages, className, ...rest } = props;
  // console.log(Lowerpages);
  // console.log(Uperpages);
  const [UpperMenu, setUpperMenu] = useState([]);
  const [LowerMenu, setLowerMenu] = useState([]);
  const auth = useContext(AuthContext);
  const NavBtns = ["Account", "Logout"];
  // const logoutHandler = (event) => {
  //   event.preventDefault();
  //   setCloseModal(true);
  //   auth.logout();
  // };
  // const cancelLogout = () => {
  //   setCloseModal(false);
  // };
  // const handleLogout = () => {
  //   setCloseModal(false);
  //   auth.logout();
  // };
  // const LogoutModal=(value)=>{
  // console.log("Majid Ali" ,value);
  // }
  // const handleClose=()=>{
  //   onClose();
  // };
  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {/* {closeModal && (
        <AlertModal
          open={closeModal}
          title={"Are You Sure You Want to Log Out?"}
          rightButton={"Yes, Log Out"}
          cancelLogout={cancelLogout}
          handleLogout={handleLogout}
        />
      )} */}
      <div className={classes.classmenu}>
        <div>
          {Uperpages.map((page) => (
            <div>
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                  onClick={()=>{onPress(page.title)}}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </ListItem>
            </div>
          ))}
        </div>

        <div>
          {Lowerpages.map((page) => (
            <ListItem className={classes.item} disableGutters key={page.title}>
              {page.title == "Account" ? (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                  onClick={()=>{onPress(page.title)}}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              ) : (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  // component={CustomRouterLink}
                  // to={page.href}
                  // onClick={LogoutModal?.(page.title)}
                  onClick={()=>{onPress(page.title)}}
                  // onClick={logoutHandler}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              )}
            </ListItem>
          ))}

          <div {...rest} className={clsx(classes.root, className)}>
            <Typography variant='body1'>
              &copy;{" "}
              <Link component='a' href='https://mernkit.com' target='_blank'>
                Grey Helix
              </Link>
              . 2022
            </Typography>
            <Typography variant='caption'>Grey Helix</Typography>
          </div>
        </div>
      </div>

      {/* {pages.map((page) => (
       
        <ListItem className={classes.item} disableGutters key={page.title}>
          {page.logout && (
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              onClick={logoutHandler}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          )}
          {!page.logout && (
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          )}
        </ListItem>
      ))} */}
    </List>
  );
};

export default SidebarNav;
