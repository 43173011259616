import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const AlertModal = ({
  open,
  title,
  subTitle,
  rightButton,
  cancelLogout,
  handleLogout,
}) => {
  return (
    <Dialog
      open={open}
      onClose={cancelLogout}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <span style={{ fontSize: "20px" }}>{title}</span>
      </DialogTitle>
      {subTitle && (
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {subTitle}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={cancelLogout} color={"default"}>
          Cancel
        </Button>
        <Button
          onClick={handleLogout}
          variant={"contained"}
          color={"secondary"}
          autoFocus
        >
          {rightButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertModal;
