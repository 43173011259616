import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";

export const useAuth = () => {
  const [hasCase, setHasCase] = useState(true);
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [profileId, setProfileId] = useState();
  const [userName, setUserName] = useState();
  const [email, setEmail] = useState();
  const [tenant, setTenant] = useState();
  const [error, setError] = useState(); // error from social
  const [authProvider, setAuthProvider] = useState(false); //false if 3rd party
  const [stripeStatus, setStripeStatus] = useState(); //status object for Stripe.
  const [rememberMe, setRememberMe] = useState(false);
  const [stripeId, setStripeId] = useState();

  const login = useCallback(
    (
      uid,
      profileId,
      userName,
      email,
      tenant,
      token,
      provider,
      status,
      rememberMe,
      stripeId
    ) => {
      setToken(token);
      setEmail(email);
      setProfileId(profileId);
      setUserName(userName);
      setTenant(tenant);
      setStripeStatus(status);
      setAuthProvider(provider);
      setRememberMe(rememberMe);
      setStripeId(stripeId);

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          profileId: profileId,
          userName: userName,
          email: email,
          tenant: tenant,
          token: token,
          authProvider: provider,
          status: status,
          rememberMe: rememberMe || false,
          stripeId: stripeId
        })
      ); //save to token to local storage.
      setUserId(uid);
    },
    []
  );

  useEffect(() => {
    if(token){
      let expirationDate = new Date(new Date().getTime() + 1000 * 60 * 60);
      Cookies.set("ghAuth", token, { expires: expirationDate });
    }
  },[token]);

  const updateSettings = useCallback(
    (company, email, stripe, token, hasCase) => {
      if (company) setTenant(company);
      if (email) setEmail(email);
      if (stripe) setStripeStatus(stripe);
      if (token) setToken(token);
      if (hasCase !== null) setHasCase(hasCase);
      
    },
    []
  );

  const logout = useCallback(
    (remember) => {
      let remb = remember !== undefined ? remember : rememberMe;
      if (!remb) {
        setToken(null);
        setUserId(null);
        setProfileId(null);
        setUserName(null);
        setEmail(null);
        setTenant(null);
        setHasCase(null);
        localStorage.removeItem("userData");
      } else {
        setToken(null);
      }
    },
    [rememberMe]
  );

  const clearError = useCallback((value) => {
    setError(value);
  }, []);

  return {
    token,
    hasCase,
    userId,
    profileId,
    userName,
    email,
    tenant,
    error,
    login,
    logout,
    clearError,
    updateSettings,
    authProvider,
    stripeStatus,
    stripeId,
  };
};
