import React, { useContext, useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AuthContext } from "../common/context/auth-context";
import { useHttpClient } from "../common/hooks/http-hook";
import LoadingSpinner from "../common/UI/LoadingSpinner";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
}));

const Refresh = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const redirectToStripeConnectedAccountSignUpLink = async (callAtEnd) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/stripe/refresh",
        "POST",
        JSON.stringify({
          profileId: auth.profileId,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData) {
        window.location.replace(responseData.url);
        callAtEnd();
      }
    } catch (err) {}
  };
  const cleanUp = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    redirectToStripeConnectedAccountSignUpLink(cleanUp);
  }, []);

  return (
    <div className={classes.root}>
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid container spacing={0} justify="center">
        <Grid item lg={8} sm={12} xl={8} xs={12}>
          <Typography variant="body1" className={classes.heading}>
            Wait till We are regenerating the Stripe Link
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Refresh;
