import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "authSlice",
    initialState: {
        user: {},
        token: "",
    },
    reducers: {
        setCredentials: (state, payload) => {
            state.user = payload;
            state.token = payload.token;
        }
    },
    extraReducers: (builder) => {}
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;