import React, { useContext } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ErrorModal from "../common/UI/ErrorModal";
import LoadingSpinner from "../common/UI/LoadingSpinner";
import { useHttpClient } from "../common/hooks/http-hook";
import { AuthContext } from "../common/context/auth-context";
import { useForm } from "../common/hooks/form-hook";

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  company: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  agentName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
      minimum: 6,
    },
  },
  number: {
    presence: { allowEmpty: false, message: "is required", type: "number" },
    length: {
      maximum: 16,
      minimum: 16,
    },
  },
  exp_month: {
    presence: { allowEmpty: false, message: "is required", type: "number" },
    length: {
      maximum: 2,
      minimum: 2,
    },
  },
  exp_year: {
    presence: { allowEmpty: false, message: "is required", type: "number" },
    length: {
      maximum: 4,
      minimum: 4,
    },
  },
  cvc: {
    presence: { allowEmpty: false, message: "is required", type: "number" },
    length: {
      maximum: 4,
      minimum: 3,
    },
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true,
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    alignItems: "center",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  policyCheckbox: {
    marginLeft: "-14px",
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}));

const SignUp = (props) => {
  const { history } = props;
  const auth = useContext(AuthContext);
  if (auth.isLoggedIn) auth.logout();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);

  const handleBack = () => {
    history.goBack();
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/users/signup",
        "POST",
        JSON.stringify({
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          company: formState.values.company,
          agentName: formState.values.agentName,
          phoneNumber: formState.values.phoneNumber,
          email: formState.values.email,
          password: formState.values.password,
          number: formState.values.number,
          cvc: formState.values.cvc,
          exp_year: formState.values.exp_year,
          exp_month: formState.values.exp_month,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.token) {
        auth.login(
          responseData.userId,
          responseData.profileId,
          responseData.userName,
          responseData.email,
          responseData.tenant,
          responseData.token,
          null,
          true,
          responseData.status,
          false,
          responseData.stripeId
        );
      }
    } catch (err) {}
  };

  return (
    <div className={classes.root}>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Logging In"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}></IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignUp}>
                <Typography className={classes.title} variant="h2">
                  Create new account
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Use your email to create new account
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("firstName")}
                  fullWidth
                  helperText={
                    hasError("firstName") ? formState.errors.firstName[0] : null
                  }
                  label="First name"
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("lastName")}
                  fullWidth
                  helperText={
                    hasError("lastName") ? formState.errors.lastName[0] : null
                  }
                  label="Last name"
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastName || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("company")}
                  fullWidth
                  helperText={
                    hasError("company") ? formState.errors.company[0] : null
                  }
                  label="Company"
                  name="company"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.company || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("agentName")}
                  fullWidth
                  helperText={
                    hasError("agentName") ? formState.errors.agentName[0] : null
                  }
                  label="Agent Name"
                  name="agentName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.agentName || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("phoneNumber")}
                  fullWidth
                  helperText={
                    hasError("phoneNumber")
                      ? formState.errors.phoneNumber[0]
                      : null
                  }
                  label="Phone Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phoneNumber || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password")}
                  fullWidth
                  helperText={
                    hasError("password") ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                  variant="outlined"
                />

                <Typography className={classes.title} variant="h2">
                  Billing Information
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Please enter your billing information. Your card will not be
                  charged until your trial period is over.
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("number")}
                  fullWidth
                  helperText={
                    hasError("number") ? formState.errors.number[0] : null
                  }
                  label="Card Number"
                  name="number"
                  onChange={handleChange}
                  type="number"
                  value={formState.values.number || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("exp_month")}
                  fullWidth
                  helperText={
                    hasError("exp_month") ? formState.errors.exp_month[0] : null
                  }
                  label="Expiration Month"
                  name="exp_month"
                  onChange={handleChange}
                  type="exp_month"
                  value={formState.values.exp_month || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("exp_year")}
                  fullWidth
                  helperText={
                    hasError("exp_year") ? formState.errors.exp_year[0] : null
                  }
                  label="Expiration Year"
                  name="exp_year"
                  onChange={handleChange}
                  type="exp_year"
                  value={formState.values.exp_year || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("cvc")}
                  fullWidth
                  helperText={
                    hasError("cvc") ? formState.errors.cvc[0] : null
                  }
                  label="CVC"
                  name="cvc"
                  onChange={handleChange}
                  type="cvc"
                  value={formState.values.cvc || ""}
                  variant="outlined"
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color="primary"
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the{" "}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </div>
                {hasError("policy") && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign up now
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Have an account?{" "}
                  <Link component={RouterLink} to="/sign-in" variant="h6">
                    Sign in
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignUp);
