import {useState, useCallback, useRef, useEffect, useContext} from 'react';
import { AuthContext } from '../context/auth-context';
import Cookies from 'js-cookie';

export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false); 
    const [error, setError] = useState(); 
    const activeHttpRequests = useRef([]); 
    const auth = useContext(AuthContext);

    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers={}) => {
    try
    {
        var isLoggedIn = Cookies.get('ghAuth');
        if (isLoggedIn === undefined) {
            auth.logout();
        }
        setIsLoading(true);        
        const httpAbortCtrl = new AbortController(); 
        activeHttpRequests.current.push(httpAbortCtrl); 
        const response = await fetch (url, {method,
            body,
            headers,
            signal: httpAbortCtrl.signal //handles cancelling connected request when moving. 
        })
        .then((response) => {
            if (response.status === 401) {
                auth.logout();
                return response;
            } else {
                return response;
            }
        });

        const responseData = await response.json();
        activeHttpRequests.current=activeHttpRequests.current.filter(reqCtrl => reqCtrl!==httpAbortCtrl);
        if (!response.ok){
            throw new Error(responseData.message);
        }
        setIsLoading(false); 
        return responseData; 
        }
        catch (err){
            setError(err.message);
            setIsLoading(false);  
            throw err; 
        }
    }

    ,[]); //empty array means function never gets recreated. 
        
    const clearError = () => {
        setError(null); 
    }
    useEffect(()=>{
        return () => {
            activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());

        }; //return function inside useffect acts as a destructor. 
    
    }, []); 
    return {isLoading, error, sendRequest, clearError}
};
