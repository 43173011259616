import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}` + '/api',
    prepareHeaders: (headers, { getState }) => {
        headers.set('content-type', 'application/json');
        const token = (getState()).auth?.token;
        if (token) {
            headers.set('authentication', `Bearer ${token}`);
        }
        return headers;
    },
})

export const api = createApi({
    baseQuery: baseQuery,
    tagTypes: ['auth', 'stripe'],
    endpoints: () => ({}),
})

export const enhancedApi = api.enhanceEndpoints({
    endpoints: () => ({
        
    }),
})
