import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ErrorModal = (props) => {
  const handleClose = () => {
    props.clearError();
  };
  const handleCancel = () => {
    props.cancelError();
  };
  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.alert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.cancelError && (
            <Button onClick={handleCancel} color="primary" autoFocus>
              Cancel
            </Button>
          )}
          <Button onClick={handleClose} color="primary" autoFocus>
            {props?.closeButtonTitle || "Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ErrorModal;
