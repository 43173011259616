import { createTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
// import "./fontstyles.css";
import "../App.css";

const theme = createTheme({
  palette,
  typography: {
    fontFamily: [
      'Mont',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize:'30px',
  },
  // typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
