import React, { useState, useContext, useEffect } from "react";
// import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import { AuthContext } from "../../../common/context/auth-context";
import { useHistory } from "react-router-dom";
// import useWindowDimensions from "../common/hooks/screenDimension-hook";
import useWindowDimensions from "../../../common/hooks/screenDimension-hook";
// import { CaseDetailModalState } from "../constants/constants";
import { SCREEN_RESOLUTION } from "../../../common/constants/constants";
// import InputIcon from "@material-ui/icons/Input";
// import { AuthContext } from "../../../common/context/auth-context";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: "white",
    color: "gray",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  LogoStyle: {
    display: "flex",
    // width: "100%",
    justifyContent: "start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },

    fontWeight: "700",
    color: theme.palette.primary.dark,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  // const mobilesize=process.env.REACT_APP_MOBILE_SIZE;
  const mobilesize=SCREEN_RESOLUTION;
  const auth = useContext(AuthContext);
  const history = useHistory();
  // console.log(mobilesize);
  const [isMobile, setMobile] = useState(false);
  const { width } = useWindowDimensions();
  // const mobileScreenHandler = () => {
  //   if (window.screen.width < process.env.REACT_MOBILE_SIZE) {
  //     setMobile(true);
  //   } else {
  //     setMobile(false);
  //   }
  // };

  // const auth = useContext(AuthContext);
  const classes = useStyles();

  // const logoutHandler = (event) => {
  //   event.preventDefault();
  //   auth.logout();
  // };
  useEffect(() => {
    // window.addEventListener("resize", mobileScreenHandler);

  }, []);
  const [notifications] = useState([]);

  return (
    <React.Fragment>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton color='inherit' onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <div className={classes.LogoStyle}>
            <img
              alt='Logo'
              src='/images/greyHelix.svg'
              // src="/images/greyHelixLogo.jpg"
              height='100%'
              width='auto'
              style={{ margin: "0px", padding: "0", marginRight: "10px" }}
            />
            <Typography
              className={classes.title}
              color='textSecondary'
              variant='h3'
            >
              GREYHELIX
            </Typography>
          </div>

          {width>=mobilesize && (
            <>
              <div>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  variant='h3'
                >
                  {getScreenNameByRoute(history.location.pathname, auth.tenant)}
                </Typography>
              </div>
            </>
          )}
          {/* {!isMobile && (
            <>
              <div>
                <Typography
                  className={classes.title}
                  color='textSecondary'
                  variant='h3'
                >
                  {getScreenNameByRoute(history.location.pathname, auth.tenant)}
                </Typography>
              </div>
            </>
          )} */}
          {/* {!isMobile && (
             <div className={classes.flexGrow} />
          )} */}
          {/* <RouterLink to="/" style={{margin:"0px", padding:"0",height:"70px"}}>
        <img
            alt="Logo"
            src="/images/greyHelix.svg"
            // src="/images/greyHelixLogo.jpg"
            height="100%"      
            width="auto"
            style={{margin:"0px", padding:"0"}}
          /><h1>Site Name</h1>
          
        </RouterLink> */}

          <Hidden>
            <IconButton color='inherit'>
              <Badge
                badgeContent={notifications.length}
                color='primary'
                variant='dot'
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {/* <Hidden mdDown>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={logoutHandler}
              >
                <InputIcon />
              </IconButton>
            </Hidden> */}
          </Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};











export default Topbar;

const getScreenNameByRoute = (pathName, userName) => {
  switch (pathName) {
    case "/dashboard":
      return `Welcome ${userName}!`;
    case "/account":
      return "Account";
    case "/cases":
      return "Cases";
    case "/appointment":
      return "Appointment";
    // case "/invoices":
    //   return "Invoices";

    default:
      return "";
  }
};
